<template>
  <div
    class="flex"
  >
    <div
      class="upload"
    >
      <div>
        <div
          class="parent align-center"
        >
          <div
            class="container-upload"
          >
            <r-icon
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1
              class="ricotta mb-4 mt-7 text-titanic"
            >
              Удалить объект "{{ title }}"?
            </h1>
            <div
              class="mb-8 font text-titanic"
            >
              Удаленный объект нельзя восстановить
            </div>
            <div
              class="mt-2 buttons"
            >
              <r-button
                width="wide"
                title="Не удалять"
                color="secondary"
                @click.native="close()"
              />
              <r-button
                width="wide"
                title="Да, удалить"
                @click.native.stop.prevent="Delete()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlaceApi from '../../api/PlaceApi';

export default {
  name: 'ObjectModal',
  props: {
    id: Number,
    title: String,
    type: String
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    async Delete() {
      const _this = this;
      const JSON = {
        id: this.id,
        action: 'delete',
        currentMainMenuNumber: this.type === 'street' ? 20 : 110,
        cityId: '36'
      };
      const api = new PlaceApi();
      await api.setSaveAdd(JSON).then(result => {

        if (!result?.error) {
          const r = _this.$router.push({
            name: 'save-objects',
            params: {}
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-upload {
  width: 800px;
}

.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
}

.buttons{
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px ){
    grid-template-columns: 1fr;
  }
}

</style>
<style>
.rir-modal__content-window {
  max-width: 900px !important;
}

.RButton.wide {
  width: 100% !important;
}
</style>
