<template>
  <div
    class="flex"
  >
    <div
      class="upload"
    >
      <div>
        <div
          class="parent align-center"
        >
          <div
            class="container-upload ml-auto mr-auto"
          >
            <r-icon
              icon="selected"
              fill="matrix"
              size="56"
            />
            <h1
              class="mb-4 mt-7 camembert"
            >
              Дублирование периода уборок
            </h1>
            <div
              class="mb-4 parmigiano troy--text"
            >
              Все данные за период c {{ dateFilter(periodTo) }}  по {{ dateFilter(periodFrom) }}, будут продублированы.
            </div>
            <r-date-picker
              click-close
              class="mb-8"
              label="Дата начала дублей"
              :is-clear-model="true"
              v-model="selectedDouble"
            />
            <div class="mt-2 flex">
              <r-button
                title="Не дублировать"
                class="flex-1 mr-4"
                :type="'secondary'"
                @click="close()"
              />
              <r-button
                title="Продублировать"
                class="flex-1"
                @click.native.stop.prevent="Copy"
                :disabled="!selectedDouble"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../api/PlaceApi';

export default {
  name: 'CopyPeriodModal',
  props: {
    periodTo: String,
    periodFrom: String
  },
  data() {
    return {
      upload: false,
      selectedDouble: null
    };
  },
  methods: {
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    close() {
      document.querySelectorAll('.RModal__close').forEach(item => {
        item.click();
      });
    },
    async Copy() {
      if(this.selectedDouble) {
        const api = new Api();
        const JSON = {
          originalFrom: this.dateFilter(this.periodTo),
          originalTo: this.dateFilter(this.periodFrom),
          duplicateFrom: this.dateFilter(this.selectedDouble)
        };

        const res = await api.copyDate(JSON).then(result => {
          if (!result?.error) {
            this.$router.push({
              name: 'index'
            });
          }
        }).catch(err => {
          console.log('err', err);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-upload {
  display: initial;
  position: absolute;
  top: calc(50% - 180px);
  margin-left: 17%;
  width: 66%;
  height: 300px;
}

</style>
