<template>
  <div
    class="delete-warn"
  >
    <div
      @click="$emit('close')"
      class="delete-warn__close pointer mr-1"
      transparent
    >
      <r-icon
        icon="close"
        size="20"
        fill="rocky"
      />
    </div>

    <div
      class="delete-warn__wrapper"
    >
      <h2
        class="delete-warn__title"
      >
        Уверены, что хотите удалить?
      </h2>
      <p
        class="delete-warn__text"
      >
        {{ target }} будет удалено. Данное действие необратимо.
      </p>

      <div
        class="delete-warn__buttons"
      >
        <r-button
          color="secondary"
          class="mr-4"
          width="wide"
          @click="$emit('close')"
          title="Нет, оставить"
        />
        <r-button
          @click="deleteTarget"
          width="wide"
          title="Да, удалить"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteWarnModal',
  props: {
    target: {
      type: String
    }
  },
  created() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },
  methods: {
    async deleteTarget() {
      await this.$store.dispatch('loadDelete', { id: this.$route.params.id }).then(result => {
        if (!result?.error) {
          let r = this.$router.resolve({
            name: 'index'
          });

          window.location.assign(r.href);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-warn {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--rir-amelie);
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__wrapper {
    max-width: 584px;
    width: 100%;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 12px;
  }

  &__text {
    opacity: 0.48;
  }

  &__buttons {
    display: flex;
    margin-top: 32px;

    .rir-button {
      white-space: nowrap;

      &:first-child {
        margin-right: 32px;
      }
    }
  }
}
</style>
<style>
.RButton.wide {
  width: 100% !important;
}
</style>
