<template>
  <div ref="content">
    <Loader v-if="isLoadings" />
    <div>
      <div
        class="flex align-center sulguni mb-7 rocky--text pointer"
        @click="linkClick()"
      >
        <r-icon
          class="mr-2 mt-2px"
          icon="arrow-left"
          fill="rocky"
          size="16"
        />
        Назад
      </div>
      <r-bulb
        :is-block="true"
        :is-shape="false"
        color="fargo"
        :title="!isSave ? 'Не сохранено' : ''"
        position="eluno"
        size="marina"
      >
        <template #content>
          <h1 class="ricotta">
            {{ type === 'street' ? 'Редактирование улицы' : (type === 'territory' ? 'Редактирование территории' : 'Редактирование двора') }}
          </h1>
        </template>
      </r-bulb>

      <div>
        <div class="grid-cont mt-8">
          <div class="edit__form">
            <r-input
              class="flex-1 custom w-100"
              value=""
              v-model="name"
              label="Название улицы"
              :params-input="{&quot;type&quot;:&quot;input&quot;}"
              @blur="isChange('name')"
            />
            <div
              class="flex align-center align-items-center custom mt-10 mb-60px pointer fargo-hover"
              @click="deleteItem()"
            >
              <r-icon
                icon="delete"
                fill="fargo"
                size="16"
              />
              <div class="fargo--text ml-2">
                {{ type === 'street' ? 'Удалить улицу' : (type === 'territory' ? 'Удалить территорию' : 'Удалить двор') }}
              </div>
            </div>
          </div>

          <div class="edit__map">
            <rir-map-leaflet
              @clickMap="onMapClick"
              @mapInit="onMapInit"
              :street="type === 'yard' ? true : false"
              :center="markers[0]?.position?.lat ? [markers[0].position.lat,this.markers[0].position.lng]  : $cityCenter"
              :key="countMap"
              collapse-btn
            >
              <div
                v-for="(item, index) in loadObjects"
                v-if="item.type === 'yard'"
                :key="countMap"
              >
                <l-marker
                  v-for="marker in markers"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                >
                  <l-icon
                    :icon-url="$markerIconTwo.imageHref"
                  />
                </l-marker>
              </div>
            </rir-map-leaflet>
            <div class="mt-5 text-footer">
              Добавьте контрольные точки маршрута на карту
            </div>
          </div>
        </div>
        <div class="button_container mt-10 work-form__btn-cont">
          <r-button
            width="wide"
            title="Сохранить"
            class="flex-1"
            @click="submit"
            :disabled="isSave"
          />
        </div>
      </div>
      <r-modal
        ref="modal"
        close-icon
      />
    </div>
  </div>
</template>

<script>
import ObjectModal from '@/components/modals/ObjectModal.vue';
import RirMapLeaflet from '@/components/maps/RirMapLeaflet.vue';
import { LMarker, LIcon } from 'vue2-leaflet';
import PlaceApi from '../../../../api/PlaceApi';
import Api from '@/api/PlaceApi';
import Loader from '@/components/ states/Loader.vue';

export default {
  components: {
    Loader,
    LMarker,
    LIcon,
    RirMapLeaflet
  },
  data() {
    return {
      markers: [],
      name: null,
      type: null,
      streetGeometry: null,
      mapInstance: null,
      address: null,
      isLoading: false,
      coordinates: [],
      isSave: true,
      countMap: 0,
      nameInit: null,
      polyline: null,
      coords: true,
      pol: null,
      listSave: []
    };
  },
  computed: {
    loadObjects() {
      let _this = this;
      let list = _this.listSave;
      if (!list) return;

      list = list.filter(el => el?.id === _this.$route.params.id);

      if (list[0]?.address !== undefined && _this.nameInit === null) {
        _this.name = list[0]?.address;
        _this.nameInit = true;
      }
      if (list[0]?.type !== undefined) {
        _this.type = list[0]?.type;
      }
      if(this.type == 'yard') {
        _this.markers = [];
        const newMarker = {
          position: { lat: list[0].lat, lng: list[0].lng },
          draggable: true,
          visible: true
        };
        this.markers.push(newMarker);
      }
      console.log(list)
      _this.coordinates = list[0]?.geometry ? (_this.type === 'street' ? list[0].geometry : list[0].geometry[0]): [[list[0].lat, list[0].lng]];
      if (_this.type !== 'yard' && _this.coords && _this.mapInstance?.mapObject) {
        if (!_this.coordinates || _this.coordinates.length === 0) {
          _this.pol = _this.type === 'street'
            ? _this.mapInstance.mapObject.editTools.startPolyline()
            : _this.mapInstance.mapObject.editTools.startPolygon();
        }
        if (_this.coordinates && _this.coordinates.length) {
          _this.polyline = _this.type == 'street'
            ? L.polyline(_this.coordinates).addTo(_this.mapInstance.mapObject)
            : L.polygon(_this.coordinates, {fillColor: '#4480F3', color: '#4480F3'}).addTo(_this.mapInstance.mapObject);
          _this.polyline.enableEdit();
          console.log(_this.polyline)
          if (_this.selectedCategory == 'street') {
            _this.polyline.editor.continueForward();
          }
        }
        _this.coords = null;
      }

      return list;
    },
    isLoadings() {
      return this.listSave?.length <= 0;
    }
  },
  watch: {
    markers: {
      deep: true,
      handler() {
      //  this.onDragMarker();
      }
    }
  },
  mounted() {
    const api = new Api();
    let _this = this;
    api.getSave().then((res) => {
      _this.listSave = res.all;
    });
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://rawgit.com/Leaflet/Leaflet.Editable/master/src/Leaflet.Editable.js'
    );
    document.head.appendChild(recaptchaScript);
  },
  updated() {
  },
  methods: {
    async deleteItem() {
      await this.$refs.modal.openModal(ObjectModal, {
        id: this.$route.params.id,
        title: this.name,
        type: this.type
      });
    },
    linkClick() {
      const r = this.$router.resolve({
        name: 'save-objects',
        params: {}
      });
      history.pushState({}, '', r.href);
    },
    isChange() {
      if (this.name !== null && this.name !== '') {
        this.isSave = false;
      }
    },
    submit() {
      this.onSave();
    },
    onMapInit(e) {
      this.mapInstance = e;
    },
    onMapClick() {
      this.isSave = false;
    },
    onDragMarker() {
      if (this.name !== null && this.name !== '') {
        this.isSave = false;
      } else {
        this.isSave = true;
      }
    },
    async onSave() {
      let lat = null;
      let lng = null;
      let territory = [];

      this.streetGeometry = [];
      let geometry = this.mapInstance.mapObject.editTools?._drawingEditor?._drawnLatLngs
        ? this.mapInstance.mapObject.editTools?._drawingEditor?._drawnLatLngs
        : (this.pol?._latlngs ? this.pol._latlngs : this.polyline._latlngs);

      geometry = this.type === 'territory' ? geometry[0] : geometry;
      if (geometry !== undefined) {
        for (var i = 0; i < geometry.length; i++) {
          const item = JSON.parse(
            String(geometry[i])
              .split('LatLng(')
              .join('[')
              .split(')')
              .join(']')
          );
          this.streetGeometry.push([item[0], item[1]]);
        }

        if(this.type === 'territory') {
          territory.push(this.streetGeometry);
        }

      } else if (this.polyline) {
        if(this.type === 'territory') {
          territory = [];
          for (let i = 0; i < this.polyline._latlngs[0].length; i++) {
            const item = JSON.parse(
              String(this.polyline._latlngs[0][i])
                .split('LatLng(')
                .join('[')
                .split(')')
                .join(']')
            );
            this.streetGeometry.push([item[0], item[1]]);
            lat = item[0];
            lng = item[1];
          }

          territory.push(this.streetGeometry);
        } else {
          for (var i = 0; i < this.polyline._latlngs.length; i++) {
            const item = JSON.parse(
              String(this.polyline._latlngs[i])
                .split('LatLng(')
                .join('[')
                .split(')')
                .join(']')
            );
            this.streetGeometry.push([item[0], item[1]]);
            lat = item[0];
            lng = item[1];
          }
        }
      }

      let JSONS = {
        id: this.$route.params.id,
        action: 'update',
        currentMainMenuNumber: 110,
        cityId: '36',
        item: {
          type: this.type === 'territory' ? 'territory' : 'street',
          address: this.name,
          geometry: this.type === 'territory' ? territory : this.streetGeometry,
          lat: null,
          lng: null
        }
      };
      if (this.type === 'yard') {
        JSONS = {
          id: this.$route.params.id,
          action: 'update',
          currentMainMenuNumber: 110,
          cityId: '36',
          item: {
            type: 'yard',
            address: this.name,
            geometry: null,
            lat: this.markers[0].position.lat,
            lng: this.markers[0].position.lng
          }
        };
      }
      const api = new PlaceApi();
      await api.setSaveAdd(JSONS).then(result => {

        if (!result?.error) {
          this.$router.push({
            name: 'save-objects',
            params: {}
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.dates {
  display: grid;
  grid-gap: 10px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.edit {
  &__map {
    width: 100%;
    height: 380px;
  }
}

.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

::v-deep .rir-map__legend {
  display: none;
}

.work-form__btn-cont {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 74.7%;
  background-color: #fff;
  z-index: 2;
  padding: 26px 40px 26px 32px;
  box-shadow: 0 9px 28px rgb(17 48 121 / 18%);
}

.w-100 {
  width: 100%;
}
</style>
<style>
.rir-date-picker__content {
  top: 143px !important;
  left: 62% !important;
}
</style>
