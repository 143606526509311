<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <router-link
      class="flex align-center sulguni mb-7 print"
      :to="{
        name: 'index',
      }"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        size="16"
      />
      Назад
    </router-link>
    <loader-model
      v-if="isLoading || loadSave"
    />
    <div
      v-else
    >
      <div>
        <h1
          class="ricotta"
          style="overflow: auto;"
        >
          Сохранённые объекты
        </h1>
      </div>
      <loader-model
        v-if="isLoading"
      />
      <div
        v-else
        v-show="information"
        :key="counts"
      >
        <r-tabs
          class="mt-7 flex-1"
          :items="tabs"
          v-model="activeTabId"
          @input="tab()"
        />

        <div
          class="flex mt-10"
        >
          <div
            class="flex-1 mr-6"
          >
            <div
              class="flex"
            >
              <div
                class="mb-4 ml-auto parmigiano text-titanic opacity-48"
              >
                Всего {{ countSave }}
              </div>
            </div>
            <r-input
              class="flex-1 custom w-100"
              value=""
              v-model="search"
              label="Поиск"
              :params-input="{&quot;type&quot;:&quot;input&quot;}"
              after-icon="search"
            >
              <template #before>
                <r-icon
                  icon="search"
                  fill="pianist"
                  size="16"
                />
              </template>
            </r-input>
            <div
              class="flex"
            >
              <router-link
                class="flex align-center sulguni rocky--text mt-4"
                :to="{ name: 'new-objects' ,
                       params: {
                         type: activeTabId.id === 'street' ? 'street' : (activeTabId.id === 'yard' ? 'yard' : 'territory')
                       }
                }"
              >
                <r-icon
                  class="mr-2"
                  icon="add"
                  fill="rocky"
                  size="16"
                />
                {{ activeTabId.id === 'street' ? 'Добавить улицу' : (activeTabId.id === 'yard' ? 'Добавить двор' : 'Добавить территорию') }}
              </router-link>
              <div
                class="flex ml-auto mt-4"
              >
                <div
                  class="titanic-hover--text sulguni opacity-72 mr-2 pt-3"
                  style="font-weight: 400;"
                >
                  Сортировка
                </div>
                <drop-down-button
                  :items="periodList"
                  @select="onChangePeriod"
                />
              </div>
            </div>
              <div
                class="overflow"
                :key="'cou_' + count"
              >
                <r-table
                  :items="itemsSave"
                  :headers="headers"
                  idValue="id"
                  :isFixedHeader="true"
                  v-model="itemsCheck"
                  colorHeader="amelie"
                  @input="clickAddress"
                  @clickRow="link"
                  size="paphia"
                >
                  <template #control="{ item }">
                    <r-tooltip
                      v-if="item.del.type === 'street' && (!item.del.geometry || (item.del.geometry.length === 1 && item.del.geometry[0].length < 2))"
                      title="Не указаны контрольные точки"
                      position="top-center"
                      max-width="200px"
                    >
                      <template #activator>
                        <div
                          class="mr-4"
                        >
                          <span
                            v-if="item.del.type === 'street' && (!item.del.geometry || (item.del.geometry.length === 1 && item.del.geometry[0].length < 2))"
                            class="legend__color lebowski--bg"
                            style="margin-top: -2px;box-shadow: 0px 2px 8px rgba(233, 163, 93, 0.64)"
                                />
                          <span
                            v-else
                            class="legend__color mt-1"
                          />
                        </div>
                      </template>
                    </r-tooltip>
                  </template>
                  <template #del="{ item }">
                    <div
                      class="flex align-items-center"
                      @click.prevent.stop="deleteItem(item.id, item.name, item.del.type)"
                    >
                      <r-icon
                        icon="delete"
                        class="block pointer"
                        fill="fargo"
                        size="16"
                      />
                    </div>
                  </template>
                </r-table>
            </div>
          </div>
          <div
            class="edit__map flex-1"
          >
            <rir-map-leaflet
              :center="coordinates || $cityCenter"
              :key="activeTabId.id"
              :legend="false"
              collapse-btn
            >
              <div
                v-for="cleaning in saveObjects"
                :key="`${cleaning.id}`"
                v-if="activeTabId.id === cleaning.type"
              >
                <div
                  v-if="cleaning.type === 'yard' && cleaning.lat"
                >
                  <l-marker
                    :lat-lng="cleaning.geometry.length === 2 ? cleaning.geometry : [cleaning.lat, cleaning.lng]"
                  >
                    <l-icon
                      :icon-url="$markerIconTwo.imageHref"
                    />
                    <l-popup
                      :options="{offset: [111, -15]}"
                    >
                      <balloon-saved-objects
                        :id="cleaning.id"
                        :title="cleaning.address"
                        :date="cleaning.createdF"
                        :address="cleaning.description"
                      />
                    </l-popup>
                  </l-marker>
                </div>
                <l-polyline
                  v-else-if="cleaning.geometry && cleaning.type === 'street'"
                  :lat-lngs="cleaning.geometry"
                  color="#4480F3"
                >
                  <l-popup
                    :options="{offset: [110, 15]}"
                  >
                    <balloon-saved-objects
                      :id="cleaning.id"
                      :title="cleaning.address"
                      :date="cleaning.createdF"
                      :address="cleaning.description"
                    />
                  </l-popup>
                </l-polyline>
                <l-polygon
                  v-else-if="cleaning.geometry && cleaning.type === 'territory'"
                  :lat-lngs="cleaning.geometry"
                  :color="'#4480F3'"
                  :fillColor="'#4480F3'"
                  :weight="2"
                >
                  <l-popup
                    :options="{offset: [110, 15]}"
                  >
                    <balloon-saved-objects
                      :id="cleaning.id"
                      :title="cleaning.address"
                      :date="cleaning.createdF"
                      :address="cleaning.description"
                    />
                  </l-popup>
                </l-polygon>
              </div>
            </rir-map-leaflet>
          </div>
        </div>
      </div>
      <div
        class="button_container mt-10 work-form__btn-cont"
      >
        <div
          class="flex"
        >
          <div
            class="flex align-items-center pointer"
          >
            <r-button-action
              :title="'Удалить выбранные '+(delCount > 0 ? '( '+delCount+' )' : '' )"
              :disabled="delCount <= 0"
              icon="delete"
              :color="'fargo'"
              size="sulguni"
              @click="deleteAll"
            />
          </div>
          <div
            class="flex align-items-center ml-6 pointer"
          >
            <r-button-action
              title="Отменить выбор"
              icon="answer"
              :color="'metropolis'"
              size="sulguni"
              @click="tab"
            />
          </div>
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import DropDownButton from '@/components/components/DropDownButton.vue';
import ObjectModal from '@/components/modals/ObjectModal.vue';
import BalloonSavedObjects from '@/components/balloons/BalloonSavedObjects.vue';
import { LMarker, LPolyline, LIcon, LPolygon, LPopup } from 'vue2-leaflet';
import RirMapLeaflet from '@/components/maps/RirMapLeaflet.vue';
import DeleteListSavedModel from '@/components/modals/delete/DeleteListSavedModel.vue';
import LoaderModel from '@/components/ states/LoaderModel.vue';

Vue.use(vClickOutside);

export default {
  components: {
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    LPolygon,
    RirMapLeaflet,
    DropDownButton,
    BalloonSavedObjects,
    LoaderModel
  },
  props: {},
  data() {
    return {
      headers: [
        {
          field: 'name',
          name: 'Наименование',
        },
        {
          field: 'control',
          name: '',
          slot: true,
          ignoreClickRow: true,
        },
        {
          field: 'del',
          name: '',
          slot: true,
          ignoreClickRow: true,
        }
      ],
      itemsSave: [],
      countSave: 0,
      count: 0,
      selectedPeriodId: 'new',
      selectedPeriod: 'новые',
      periodList: [
        { id: 'sort', active: false, value: 'Сначала на "А"' },
        { id: 'reverse', active: false, value: 'Сначала на "Я"' },
        { id: 'new', active: true, value: 'Новые' },
        { id: 'old', active: false, value: 'Старые' }
      ],
      tabs: [
        {
          id: 'street',
          title: 'Улицы',
          optionsBulb: {
            isShape: false,
            title: '',
            position: 'eluno'
          }
        },
        {
          id: 'yard',
          title: 'Дворы',
          optionsBulb: {
            isShape: false,
            title: '',
            position: 'eluno'
          }
        },
        {
          id: 'territory',
          title: 'Территории',
          optionsBulb: {
            isShape: false,
            title: '',
            position: 'eluno'
          }
        }
      ],
      activeTabId: {
        id: 'street',
        title: 'Улицы',
        optionsBulb: {
          isShape: false,
          title: '',
          position: 'eluno'
        }
      },
      isActive: Boolean,
      counts: 0,
      items: [],
      isLoad: false,
      coordinates: null,
      loadSave: false,
      information: true,
      flyButton: true,
      searchText: null,
      timeout: null,
      all: false,
      delCount: 0,
      JSON: [],
      itemsCheck: []
    };
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },
    saveObjects() {
      let _this = this;
      let list = this.$store?.state?.saveInfo;
      if (this.searchText) {
        list = list.filter(el => ((el?.address.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)));
      }
      if (list && this.selectedPeriodId === 'sort') {
        list = _.sortBy(list, 'address');
      }
      if (list && this.selectedPeriodId === 'reverse') {
        list = _.sortBy(list, 'address').reverse();
      }
      if (list && this.selectedPeriodId === 'old') {
        list = _.sortBy(list, 'created');
      }
      if (list && this.selectedPeriodId === 'new') {
        list = _.sortBy(list, 'created').reverse();
      }
      this.countSave = 0;
      if (list) {
        list = list.filter(el => (((el.type === 'street') || el.type === 'yard' || el.type === "territory")));
        list = list.filter(el => ((this.activeTabId.id === el.type)));
        this.itemsSave = [];
        list.forEach(function (item) {
          _this.itemsSave.push({id: item.id,name: item.address, control: '', del: {type: item.type, geometry: item.geometry}})
        });

        this.countSave = list.length;
      }
      return list;
    },
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    }
  },
  watch: {},
  mounted() {
    this.$store.dispatch('loadInfoSave');

    this.isLoad = false;
  },
  methods: {
    async deleteItem(id, title, type) {
      await this.$refs.modal.openModal(ObjectModal, {
        id,
        title,
        type
      });
    },
    onChangePeriod(e) {
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      this.count++;
    },
    clickAddress() {
      let _this = this;
      let count = 0;
      _this.JSON = [];

      _this.itemsCheck.forEach(function (item) {
        _this.JSON.push(Number(item));
        count++;
      });
      _this.delCount = count;
    },
    deleteAll() {
      this.$refs.modal.openModal(DeleteListSavedModel, {
        JSON: this.JSON,
        info: this.delCount
      });
    },
    tab() {
      this.all = false;
      this.delCount = 0;
      this.JSON = [];
      this.itemsCheck = [];
      this.count++;
    },
    active() {
      const item = {
        active: !this.isActive,
        id: this.$store.state.loadId.id
      };
      this.$store.dispatch('loadActive', item).then(result => {
        if (typeof (result.updated) !== 'undefined') {
          this.isActive = !this.isActive;
        }
      });
    },
    link(item) {
      let id = item.id;
      this.$router.push({
        name: 'edit-objects',
        params: { id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit {
  &__map {
    display: block;
    height: 400px;
  }
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.overflow {
  height: 460px;
  overflow-y: scroll;
}

.overflow::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #f6f9fe;
}

.overflow::-webkit-scrollbar {
  width: 4px;
  background-color: #f6f9fe;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(61, 117, 228, .08);
}

.legend {
  &__color {
    display: block;
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.w-100 {
  width: 100%;
}
</style>
<style>
.RTable td {
  vertical-align: middle;
}

.RButtonAction {
  background-color: transparent !important;
}
</style>
