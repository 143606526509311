<template>
  <r-popover
    :disabled="false"
    :fixed="false"
    :click-close="false"
    :content-width-activator="false"
    :content-class="null"
    :content-coefficient-width="1"
    position="left-bottom"
    v-model="showSelect"
  >
    <template #activator="{ on }">
      <r-button-action
        :color="!xlsNew ? 'fargo' : 'harakiri'"
        v-on="on"
        :title="model != null ? model : (xlsNew ? xlsNew : 'Не выбрано')"
        is-arrow
      />
    </template>
    <template #content>
      <div style="z-index: 666666;">
        <div class="flex rir-select__list_item">
          <r-input
            label="Поиск"
            v-model="searchText"
            after-icon="search"
          />
        </div>
        <div
          style="max-width: 306px;max-height: 150px;z-index: 666666;overflow-y: scroll;"
          class="scroll-y"
        >
          <r-tooltip
            :title="item[textValue]"
            v-for="item in itemsList"
            v-if="itemsList.length"
            max-width="450px"
            position="center-right"
          >
            <template #activator>
              <r-list-item
                @click.native="!item.disabled && selectItem(item)"
                :class="{
                  active: item[idValue] === modelId,
                  disabled: item.disabled
                }"
                :title="item[textValue]"
              />
            </template>
          </r-tooltip>
          <div
            class="rir-select__no-date"
            v-if="itemsList.length <= 0"
          >
            {{ noDataItems }}
          </div>
        </div>
      </div>
    </template>
  </r-popover>
</template>

<script>

import PlaceApi from '@/api/PlaceApi';

export default {
  name: 'RirSelectItem',
  props: {
    value: {
      validator: prop => ['string', 'object', 'number'].includes(typeof prop) || prop === null,
      default: () => ({})
    },
    returnObject: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    items: {
      type: Array,
      default: () => []
    },
    textValue: {
      type: String,
      default: 'value'
    },
    xls: {
      type: String,
      default: null
    },
    xlsNew: {
      type: String,
      default: null
    },
    idValue: {
      type: String,
      default: 'id'
    },
    noDataItems: {
      type: String,
      default: 'Список пустой'
    },
    selectAll: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    selectFirstItem: {
      type: Boolean
    },
    error: {
      type: Boolean,
      default: false
    },
    hiddenItem: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showSelect: false,
    item: null,
    searchText: ''
  }),
  computed: {
    model() {
      return this.item ? this.item[this.textValue] : null;
    },
    modelId() {
      return this.item ? this.item[this.idValue] : null;
    },
    itemsList() {
      const items = this.items.map(el => ({
        ...el
      }));
      if (this.searchText && this.searchText.length > 0) {
        return items.filter(result => (
          result.value.toLowerCase().includes(this.searchText.toLowerCase())
        ));
      }
      return items;
    },
    isSlotItem() {
      return !!this.$slots.item || !!this.$scopedSlots.item;
    }
  },
  watch: {
    value(val) {
      this.item = this.itemsList.find(
        el => el[this.idValue] === (this.returnObject ? val[this.idValue] : val)
      );
    }
  },
  mounted() {
    if (this.selectFirstItem) {
      this.item = this.items[0];
    }
    this.item = this.itemsList.find(
      el => el[this.idValue]
        === (this.returnObject ? this.value[this.idValue] : this.value)
    ) || null;
  },
  methods: {
    changeViewList() {
      this.showSelect = !this.readonly && !this.showSelect;
    },
    selectItem(item) {
      this.item = { ...item };
      const value = {
        addressId: this.item.id,
        address: this.item.value,
        addAcronym: this.xls
      };

      const api = new PlaceApi();
      api.operateAddresses(value).then(resp => {
      });
      delete item.forUuId;
      const val = this.returnObject ? item : item[this.idValue];
      this.$emit('change', item);
      this.$emit('input', val);
      this.showSelect = false;
    }
  }
};
</script>
<style scoped lang="scss">
.rir-select__content {
  position: relative;
  ::v-deep .rir-input__body{
    pointer-events: none;
  }
}

.rir-select__list {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  border-radius: 8px;
  width: 100%;
  left: 0;
  top: calc(100% + 8px);
  padding: 8px 0;
  z-index: 12;
  max-height: 250px;

  .scroll_container {
    overflow-y: auto;
    max-height: 178px;
  }

  &_item {
    z-index: 111111111;
    max-height: 400px;
    padding: 8px 8px;
    transition: .2s ease-in-out;
    cursor: pointer;

    p {
      transition: .2s ease-in-out;

    }
  }
}
.point {
  max-width: 200px;
  max-height: 33px;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.scroll-y::-webkit-scrollbar, .scroll-y::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72;
}

.scroll-y::-webkit-scrollbar-track {
  width: 4px;
  background: #f6f9fe;
}
.rir-select__no-date {
  text-align: center;
  font-weight: 700;
  margin-left: 74px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
<style>
.RPopover__content {
  z-index: 5000000 !important;
  overflow: unset !important;
}
.RButtonAction__title {
  display: -webkit-inline-box;
  overflow: hidden;
  font-weight: 500;
  white-space: inherit;
  word-wrap: anywhere;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: left;
}
.RTooltip__content {
  z-index: 666666666666 !important;
}
</style>
