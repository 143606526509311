<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <router-link
      class="flex align-center sulguni mb-7 print"
      :to="{
        name: 'save',
      }"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        fill="rocky"
      />
      Назад
    </router-link>
    <loader v-if="isLoading || loadSave" />
    <div v-else>
      <div>
        <div v-if="items['approved'] === '0'">
          <div
            class="up fargo--text"
          >
            Ожидает одобрения
          </div>
        </div>
        <div v-if="items['approved'] === '1'">
          <div
            class="up"
            v-if="(items['eventForm']?.datesExtra ? getCleaningStatus() === 'wait' : false)"
          >
            Ожидается уборка
          </div>
          <div
            class="up"
            v-if="(items['eventForm']?.datesExtra ? getCleaningStatus() === 'done' : true)"
            style="color: #57A003"
          >
            Уборка завершена
          </div>
          <div
            class="up"
            v-if="(items['eventForm']?.datesExtra ? getCleaningStatus() === 'progress' : false)"
            style="color: #3D75E4"
          >
            Идет уборка
          </div>
        </div>
        <r-bulb
          :is-block="true"
          :is-shape="false"
          color="fargo"
          :title="!isSave ? 'Не сохранено' : ''"
          position="eluno"
          size="marina"
        >
          <template #content>
            <h1
              class="ricotta"
            >
              {{ items['title'] }}
            </h1>
          </template>
        </r-bulb>
        <div class="street-house flex sulguni obj-card__title mozzarella style-marin-hous text-up">
          <div class="mr-6 flex sulguni text-up">
            <r-icon
              icon="geopoint"
              fill="titanic"
              class="anie margin-right"
            />
            <div class="hidden__block">
              {{ items['eventForm'] ? items['eventForm'].address : '-' }}
            </div>
          </div>
          <div class="mr-6 flex sulguni text-up">
            <r-icon
              icon="clock"
              fill="titanic"
              class="anie margin-right icon-placeholder"
            />
            {{ items['eventForm']?.datesExtra ? onChangePeriod(items['eventForm'].datesExtra[0]) : '-' }}
          </div>
          <div class="mr-6 flex sulguni text-up">
            <r-icon
              icon="avatar"
              fill="titanic"
              class="anie margin-right icon-placeholder"
            />
            {{ items['user'] ? items['user'].fio : '-' }}
          </div>
        </div>
        <div
          class="mb-4 table"
          v-if="items['approved'] === '1'"
        >
          <r-tabs
            class="mt-7"
            :items="[{
              id: 'index',
              title: 'Общая информация',
            },{
              id: 'comment',
              title: 'Комментарии и итоги',
              optionsBulb: {
                color: 'matrix',
                isShape: false,
                title: countComm,
                position: 'eluno'
              }
            }]"
            v-model="activeTabId"
          />
        </div>
      </div>
      <loader v-if="isLoading" />
      <div
        v-else
        v-show="activeTabId.id === 'index'"
        :key="counts"
      >
        <div
          class="grid-cont mt-8 mb-120"
        >
          <div class="block-hiden-too">
            <div>
              <r-input
                class="flex-1 custom"
                value=""
                v-model="items['title']"
                label="Название"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
            <r-date-picker
              label="Дата"
              class="mt-5"
              v-model="date"
              @input="onClickDate"
              :is-input="false"
              :selected="cleaningDates"
              :error="!cleaningDates.length"
            />
            <div class="h-250">
              <div
                class="selected-dates"
                v-if="!!formatDates.length"
              >
                <div
                  class="selected-dates__badge"
                  v-for="(date, index) in formatDates"
                  :key="index"
                >
                  {{ date }}

                  <button
                    class="selected-dates__clear"
                    @click="clearDate(index)"
                  >
                    <r-icon
                      icon="close"
                      fill="amelie"
                      size="16"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="grid-time mt-5">
              <r-time-picker
                v-model="timeTo"
                class="mr-6"
                label="Время c"
                :disabled="false"
              />
              <r-time-picker
                v-model="timeFrom"
                label="Время по"
                :disabled="false"
              />
            </div>
            <r-textarea
              class="mt-6"
              :value="items['comment']"
              label="Описание"
              :rows="6"
              :max-rows="6"
              v-model="items['comment']"
            />
            <h2
              class="taleggio mt-6 mb-4"
            >
              Обложка
            </h2>
            <upload-file-input
              :count-file="1"
              upload-url="/uploadfile/index.php"
              :value="filesUpload"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
            />
            <!--            <div  v-if="items['photo0'] !== ''" style="display: table;width: 100%;" class="mt-6">-->
            <!--              <img @click="expandPhoto" :src="items['photo0']" class="rounded-8"-->
            <!--                   style="position: absolute; height: 56px;"/>-->
            <!--              <div class="clear">-->
            <!--              </div>-->

            <!--              <div style="float: right;margin-top: -38px;cursor: pointer;"-->
            <!--                   class="float-left mr-5 block pointer eye" @click="deletePhoto" >-->
            <!--                <rir-icon icon="delete" class="block" fill="#E14761"/>-->
            <!--              </div>-->
            <!--            </div>-->

            <div v-if="items['approved'] === 0">
              <h2 class="taleggio mt-6 mb-1">
                Запрос на инвентарь
              </h2>
              <div class="text">
                {{ itemsDef.equipment }}
              </div>
              <template>
                <div class="flex mt-2">
                  <r-radio-button
                    class="mr-6 mb-3 flex"
                    v-model="itemsDef.equipmentStatus"
                    val="approved"
                    title="Одобрить"
                  />
                  <r-radio-button
                    class="mr-6 flex"
                    v-model="itemsDef.equipmentStatus"
                    val="notApproved"
                    title="Отклонить"
                  />
                </div>
              </template>
              <r-textarea
                class="mt-4 mb-6"
                :value="itemsDef.equipmentReply"
                label="Ответ"
                :rows="3"
                :max-rows="6"
                v-model="itemsDef.equipmentReply"
              />
              <h2 class="taleggio mt-6 mb-1">
                Запрос на спецтранспорт или механизмы
              </h2>
              <div class="text">
                {{ itemsDef.specialVehicle }}
              </div>
              <template>
                <div class="flex mt-2">
                  <r-radio-button
                    class="mr-6 mb-3 flex"
                    v-model="itemsDef.specialVehicleStatus"
                    val="approved"
                    title="Одобрить"
                  />
                  <r-radio-button
                    class="mr-6 flex"
                    v-model="itemsDef.specialVehicleStatus"
                    val="notApproved"
                    title="Отклонить"
                  />
                </div>
              </template>
              <r-textarea
                class="mt-4 mb-6"
                :value="itemsDef.specialVehicleReply"
                label="Ответ"
                :rows="3"
                :max-rows="6"
                v-model="itemsDef.specialVehicleReply"
              />
            </div>
            <div v-if="items['approved'] === 1">
              <h2 class="taleggio mt-6 mb-1">
                Запрос на инвентарь
              </h2>
              <div class="flex align-items-center">
                <r-icon
                  v-if="itemsDef.equipmentStatus === 'approved'"
                  icon="selected"
                  class="block"
                  fill="matrix"
                />
                <r-icon
                  v-if="itemsDef.equipmentStatus === 'notApproved'"
                  icon="close"
                  class="block"
                  fill="fargo"
                />
                <span class="ml-2 mt-2px">
                  {{ itemsDef.equipmentStatus === 'approved' ? 'Одобрено' : 'Отклонено' }}
                </span>
              </div>
              <div
                class="text mt-3 w-100"
              >
                {{ itemsDef.equipment }}
              </div>
              <div
                class="mt-3 w-100"
              >
                {{ itemsDef.equipmentReply }}
              </div>
              <h2 class="taleggio mt-6 mb-1">
                Запрос на спецтранспорт или механизмы
              </h2>
              <div class="flex align-items-center">
                <r-icon
                  v-if="itemsDef.specialVehicleStatus === 'approved'"
                  icon="selected"
                  class="block"
                  fill="matrix"
                />
                <r-icon
                  v-if="itemsDef.specialVehicleStatus === 'notApproved'"
                  icon="close"
                  class="block"
                  fill="fargo"
                />
                <span class="ml-2 mt-2px">
                  {{ itemsDef.specialVehicleStatus === 'approved' ? 'Одобрено' : 'Отклонено' }}
                </span>
              </div>
              <div
                class="text mt-3 w-100"
              >
                {{ itemsDef.specialVehicle }}
              </div>
              <div
                class="mt-3 w-100"
              >
                {{ itemsDef.specialVehicleReply }}
              </div>
            </div>
          </div>
          <div class="edit__map">
            <rir-map-leaflet
              :legend="false"
              collapse-btn
            >
              <l-marker
                v-for="marker in markers"
                :key="marker.id"
                :visible="marker.visible"
                :draggable="marker.draggable"
                :lat-lng.sync="marker.position"
              >
                <l-icon
                  :icon-url="$markerIconTwo.imageHref"
                />
              </l-marker>
            </rir-map-leaflet>
            <div class="message">
              Чтобы изменить положение на карте, перетащите точку
            </div>
            <div class="margin-info">
              <r-input
                class="flex-1 custom w-100"
                value=""
                v-model="itemsDef.address"
                label="Адрес"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
                @keyup="onCoordinates(itemsDef.address)"
              />
            </div>
          </div>
        </div>
        <div class="block-hiden block-hiden-one">
        </div>
        <div class="button_container mt-10 flex">
          <r-button
            :title="items['approved'] === 1 ? 'Сохранить' : 'Опубликовать'"
            class="flex-1 mr-6"
            @click="save"
            :disabled="cleaningDates.length === 0 || !items['title'] || !itemsDef.address || !itemsDef.time || (items['approved'] === 1 ? isSave : false)"
          />
          <r-button
            title="Удалить"
            class="flex-1 rocky rocky--text"
            @click="deleteItem"
          />
        </div>
      </div>
      <div
        v-show="activeTabId.id === 'comment'"
        class="w-650"
      >
        <div
          class="comment__result"
          v-if="items['commentResult'] !== ''"
        >
          <div class="flex">
            <div
              class="title-text"
            >
              Итоги проведения от {{ items['createdF'] }}
            </div>
            <div
              class="flex align-center"
            >
              <additional-menu
                :position="{
                  top: 'calc(100% + 16px)',
                  right: '0'
                }"
              >
                <ul class="obj-card__menu">
                  <li
                    class="sulguni align-items-center flex pointer"
                    @click="clickUploadEnd(items)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="edit"
                      fill="rocky"
                      size="16"
                    />
                    Редактировать
                  </li>
                  <li
                    class="sulguni align-items-center flex mt-6 pointer"
                    @click="deleteCommEnd(items)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="delete"
                      fill="fargo"
                      size="16"
                    />
                    Удалить
                  </li>
                </ul>
              </additional-menu>
            </div>
            <!-- <div class="title-text ml-3" style="color: #57A003">Новый</div> -->
          </div>
          <div
            class="mt-4 flex"
            v-if="items.userId"
          >
            <picture
              class="mr-4"
            >
              <img
                class="image"
                :src="'/mob_ajax.php?getAvatarForUser=' + items.userId"
              >
            </picture>
            <div>
              <div class="title">
                {{ items['fio'] }}
              </div>
            </div>
          </div>
          <div
            class="mt-2 comment justify"
          >
            {{ items['commentResult'] }}
          </div>
          <div v-if="items['photoResult0']">
            <img
              :src="items['photoResult0']"
              class="mt-2 pointer photo"
              @click="showImg"
            >
          </div>
          <image-modal
            :item="items['photoResult0']"
            ref="images"
          />
        </div>
        <div
          class="comment__items"
          v-for="item in itemsComment"
          v-if="item.hidden === 0"
        >
          <div class="flex">
            <div
              class="title-text"
            >
              Комментарий от {{ item.createdF }}
            </div>
            <div
              class="flex align-center"
            >
              <additional-menu
                :position="{
                  top: 'calc(100% + 16px)',
                  right: '0'
                }"
              >
                <ul class="obj-card__menu">
                  <li
                    class="sulguni align-items-center flex pointer"
                    @click="clickUpload(item)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="edit"
                      fill="rocky"
                      size="16"
                    />
                    Редактировать
                  </li>
                  <li
                    class="sulguni align-items-center flex mt-6 pointer"
                    @click="deleteComm(item)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="delete"
                      fill="fargo"
                      size="16"
                    />
                    Удалить
                  </li>
                </ul>
              </additional-menu>
            </div>
            <!-- <div class="title-text ml-3" style="color: #57A003">Новый</div> -->
          </div>
          <div
            class="mt-4 flex"
            v-if="item.fio"
          >
            <picture
              class="mr-4"
            >
              <img
                :src="'/mob_ajax.php?getAvatarForUser=' + items.userId"
                class="padding"
              >
            </picture>
            <div>
              <div class="title">
                {{ item.fio }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mt-5">
              <div class="title">
                Аноним
              </div>
            </div>
          </div>

          <div
            class="mt-2 comment justify"
          >
            {{ item.comment }}
          </div>
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
    <upload-modal
      ref="upload"
      @save="onUpload"
    />
    <div
      class="load-change"
      v-show="modal"
    >
      <div class="image-load">
        <img src="../../../../../public/load.png">
      </div>
      <div class="load-text load-text-error">
        <div class="error-load">
          Комментарий удален
        </div>
      </div>
      <div class="load-text-right">
        <r-icon
          icon="restore"
          fill="fargo"
        />
        <div class="text">
          Восстановить
        </div>
      </div>
    </div>
    <transition name="fade">
      <delete-warn-modal
        target="Мероприятие"
        @close="openWarn = false"
        v-if="openWarn"
      />
    </transition>
    <delete-comment-modal ref="comment" />
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import AdditionalMenu from '@/components/components/AdditionalMenu.vue';
import DeleteWarnModal from '@/components/modals/delete/DeleteWarnModal.vue';
import Loader from '@/components/ states/Loader.vue';
import _ from 'lodash';
import UploadFileInput from '@/components/files/UploadFileInput.vue';
import ApiService from '@/api/ApiService';
import PlaceApi from '@/api/PlaceApi';
import { LMarker, LIcon } from 'vue2-leaflet';
import RirMapLeaflet from '@/components/maps/RirMapLeaflet.vue';
import ImageModal from '../../../modals/image/ImageModal.vue';
import UploadModal from '../../../modals/UploadModel.vue';
import DeleteCommentModal from '@/components/modals/delete/DeleteCommentModal.vue';

Vue.use(vClickOutside);

export default {
  components: {
    LMarker,
    LIcon,
    RirMapLeaflet,
    UploadFileInput,
    AdditionalMenu,
    UploadModal,
    ImageModal,
    DeleteWarnModal,
    Loader
  },
  props: {},
  data() {
    return {
      calendarDate: null,
      cleaningDates: [],
      mark: true,
      markers: [],
      activeTabId: {
        id: 'index',
        title: 'Общая информация'
      },
      modal: false,
      countComm: 0,
      files: [],
      file: [],
      time: false,
      timeTo: '00:00',
      timeFrom: '00:00',
      isActive: Boolean,
      expandImg: false,
      openWarn: false,
      date: null,
      itemsDef: {
        address: '',
        time: '',
        date: '',
        dateEnd: '',
        equipment: '',
        specialVehicle: '',
        equipmentReply: '',
        equipmentStatus: 'partiallyApproved',
        equipmentFiles: '',
        pecialVehicleReply: '',
        specialVehicleStatus: 'partiallyApproved',
        specialVehicleFiles: ''
      },
      counts: 0,
      items: [],
      filesUpload: [],
      isLoad: false,
      address: null,
      coordinates: [64.91185059862988, 77.78357681226097],
      isButton: true,
      isCovid: Boolean,
      value: '',
      coors: false,
      loadSave: false,
      information: true,
      observation: false,
      itemsComment: [],
      purgeDeadline: true,
      flyButton: false,
      isSave: true
    };
  },
  computed: {
    apiAxios() {
      return new ApiService();
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    formatDates() {
      return this.cleaningDates.map(date => {
        const nDate = new Date(date);
        return nDate.toLocaleDateString('RU-ru');
      });
    }
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.isSave = _.isEqual(newValue, this.initialData);
      }
    },
    markers: {
      immediate: true,
      deep: true,
      handler() {
        if (this.mark) {
          this.onDragMarker();
        }
      }
    }
  },
  updated() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
  },
  mounted() {
    if (this.$route.query.comments) {
      this.information = false;
      this.observation = true;
    }

    this.$store.dispatch('loadComments', { id: this.$route.params.id }).then(result => {
      this.itemsComment = result?.all || [];
      this.itemsComment = this.itemsComment.filter(el => el.hidden === 0);
      this.countComm = this.itemsComment.length;
    });
    this.items = new Array();
    this.$store.dispatch('loadActivity', { id: this.$route.params.id }).then(result => {
      this.items = result.all[0];
      if (result.all[0].eventForm !== null) {
        this.items.eventForm = result.all[0].eventForm;
        this.itemsDef = result.all[0].eventForm;
      }
      if (result?.all[0]?.eventForm?.datesExtra) {
        this.cleaningDates = result.all[0]?.eventForm?.datesExtra ? result.all[0].eventForm.datesExtra : '-';
      }
      if (this.itemsDef.time) {
        const time = this.itemsDef.time.split(' - ');
        this.timeTo = this.formatDateHour(time[0]);
        this.timeFrom = this.formatDateHour(time[1]);
      }
      this.counts++;
      if (this.items.photo0) {
        this.filesUpload = [{
          type: 'image',
          size: 0,
          name: this.items.photo0.split('/')[this.items.photo0.split('/').length - 1],
          url: this.items.photo0
        }];
      }
      this.items.title = result.all[0].title ? result.all[0].title : '';
      this.items.lat = result.all[0].lat ? result.all[0].lat : 64.91185059862988;
      this.items.lng = result.all[0].lng ? result.all[0].lng : 77.78357681226097;
      this.items.approved = result.all[0].approved;
      this.items.commentResult = result.all[0].commentResult;
      this.items.createdF = result.all[0].createdF;
      if (result.all[0]?.commentResult !== '') {
        this.countComm++;
      }

      this.coordinates = [this.items.lat, this.items.lng];
      const newMarker = {
        position: { lat: this.coordinates[0], lng: this.coordinates[1] },
        draggable: true,
        visible: true
      };
      if (this.markers.length === 0) {
        this.markers.push(newMarker);
      }
      if (this.$route.params.bul === 'true') {
        this.modal = true;
        setTimeout(() => {
          this.modal = false;
        }, 2000);
      }
      this.initialData = JSON.parse(JSON.stringify(this.items));
    }, error => {
      console.error(error);
    });
    this.isLoad = false;
  },
  methods: {
    clearDate(index) {
      this.cleaningDates.splice(index, 1);
      this.isSave = false;
    },
    onClickDate(date) {
      if (!date) return;

      const index = this.cleaningDates.findIndex(el => el === date);
      this.isSave = false;
      if (index !== -1) {
        this.cleaningDates.splice(index, 1);
      } else {
        this.cleaningDates.push(date);
      }
    },
    formattedFiles(files) {
      const formattedFiles = [];
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      return formattedFiles;
    },
    uploadFile(files) {
      if (!files?.length) {
        this.filesUpload = [];
        this.items.photo0 = null;
        return;
      }
      this.filesUpload = this.formattedFiles(files);
      this.items.photo0 = this.filesUpload[0].url;
    },
    onChangePeriod(e) {
      const toDay = new Date(e);
      const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'

      };
      const day = toDay.toLocaleString('ru-Ru', options);

      return day;
    },
    showImg() {
      this.$refs.images.showImages();
    },
    onClickOutside() {
      this.time = false;
    },
    formatDateHour(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = `0${hh}`;
      let mm = time.split(':')[1];
      if (mm < 10) mm = `${mm}`;

      return `${hh}:${mm}`;
    },
    formatDateBack(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    },
    deleteCommEnd(item) {
      this.isLoad === true;
      this.items.eventForm = {
        address: this.itemsDef.address,
        datesExtra: this.cleaningDates,
        date: this.itemsDef.date,
        equipment: this.items.eventForm !== null ? this.items.eventForm.equipment : '',
        equipmentFiles: '',
        equipmentReply: this.itemsDef.equipmentReply,
        equipmentStatus: this.itemsDef.equipmentStatus,
        specialVehicleReply: this.itemsDef.specialVehicleReply,
        specialVehicle: this.items.eventForm !== null ? this.items.eventForm.specialVehicle : '',
        specialVehicleFiles: '',
        specialVehicleStatus: this.itemsDef.specialVehicleStatus,
        time: `${this.formatDateBack(this.timeTo)} - ${this.formatDateBack(this.timeFrom)}`
      };
      this.items.id = Number(this.items.id);
      this.items.lat = this.markers[0].position.lat;
      this.items.lng = this.markers[0].position.lng;
      this.items.commentResult = '';
      this.items.photoResult0 = '';
      this.$store.dispatch('loadSave', this.items).then(result => {
        this.$store.dispatch('saveApprove', this.items).then(item => {
          if (!item?.error) {
            this.$router.push({
              name: 'clear-edit',
              params: { id: this.$route.params.id, bul: true }
            });

          }
        });
      }, error => {
        console.error(error);
      });
    },
    deleteComm(item) {
      this.$refs.modal.openModal(DeleteCommentModal, {
        item
      });
    },
    onUpload(data) {
      this.itemsComment = this.itemsComment.map(el => {
        if (el?.claimId === data.claimId) {
          el = data;
        }
      });
    },
    clickUpload(items) {
      this.$refs.upload.uploadContainer(items);
    },
    clickUploadEnd(items) {
      this.$refs.upload.uploadContainerEnd(items);
    },
    deleteItem() {
      this.openWarn = true;
    },
    getCleaningStatus() {
      let dates = this.items['eventForm']?.datesExtra;
      let date = dates ? dates[0]: '-';
      let dateEnd = dates ? dates[dates.length - 1]: '-';

      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (this.timeTo && this.timeFrom) {
        from = this.timeTo.split(':')[0];
        to = this.timeFrom.split(':')[0];
      }
      let status = 'wait';
      const hours = new Date().getHours();
      if (toDay >= date && toDay <= dateEnd) {
        status = 'progress';
        if (toDay === date) {
          if (hours < from) {
            status = 'wait';
          }
        }

        if (toDay === dateEnd) {
          if (hours < from) {
            status = 'progress';
          }
          if (hours > to) {
            status = 'done';
          }
        }
      }

      if (new Date(dateEnd) < new Date()) {
        status = 'done';
      }

      return status;
    },
    active() {
      const item = {
        active: !this.isActive,
        id: this.$store.state.loadId.id
      };
      this.$store.dispatch('loadActive', item).then(result => {
        if (typeof (result.updated) !== 'undefined') {
          this.isActive = !this.isActive;
        }
      });
    },
    async onDragMarker(e) {
      if(this.markers[0]?.position?.lat) {
        const api = new PlaceApi();
        const geo = await api.getGeoSearch({
          lat: this.markers[0].position.lat,
          lng: this.markers[0].position.lng
        });

        const {address} = geo;
        this.mark = true;
        if (address) this.itemsDef.address = address;
      }
    },
    async onCoordinates(item) {
      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ address: item.trim() });

      const { lat, lng } = geo;
      this.mark = false;
      this.markers[0].position = { lat, lng };
      this.mark = true;
    },
    save() {
      this.isLoad === true;
      this.items.eventForm = {
        address: this.itemsDef.address,
        datesExtra: this.cleaningDates,
        date: this.itemsDef.date,
        equipment: this.items.eventForm !== null ? this.items.eventForm.equipment : '',
        equipmentFiles: '',
        equipmentReply: this.itemsDef.equipmentReply,
        equipmentStatus: this.itemsDef.equipmentStatus,
        specialVehicleReply: this.itemsDef.specialVehicleReply,
        specialVehicle: this.items.eventForm !== null ? this.items.eventForm.specialVehicle : '',
        specialVehicleFiles: '',
        specialVehicleStatus: this.itemsDef.specialVehicleStatus,
        time: `${this.formatDateBack(this.timeTo)} - ${this.formatDateBack(this.timeFrom)}`

      };
      this.items.id = Number(this.items.id);
      this.items.lat = this.markers[0].position.lat;
      this.items.lng = this.markers[0].position.lng;
      this.$store.dispatch('loadSave', this.items).then(result => {
        if (this.items.approved === '0') {
          this.items.approved = '1';
          this.$store.dispatch('saveApprove', this.items).then(item => {

            if (!item?.error) {
              const r = this.$router.resolve({
                name: 'save'
              });
              window.location.assign(r.href)
            }
          });
        } else {
          if (!result?.error) {
            const r = this.$router.resolve({
              name: 'save'
            });
            window.location.assign(r.href)
          }
        }
      }, error => {
        console.error(error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__icon {
    align-self: baseline;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 14px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.text-up {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.up {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #D06E0B;
}

.comment {
  overflow: hidden;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  width: 80%;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
  padding-right: 12px;
  width: 90%;
  padding-top: 12px;
}

.line {
  position: static;
  height: 4px;
  background: #3D75E4;
  border-radius: 2px;
  margin: 8px 0px;
}

.link-information {
  cursor: pointer;
  display: table;
  font-style: normal;
  font-weight: 500;
}

.link-1 {
  cursor: pointer;
  display: table;
  font-style: normal;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #04153E;
}

.obj-card {
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.edit {
  &__map {
    display: block;
    height: 400px;
  }
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.text-covid {
  display: table;
  width: calc(100% - 174px)
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  cursor: pointer;
  margin-top: 36px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  @include reset-list;
  width: 400px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;

  opacity: 0.48;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-top: 0px !important;
  margin-right: 12px;
}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
}

.icon-placeholder {
  display: inline-block; /* Строчно-блочный элемент */
  position: relative; /* Относительное позиционирование */
}

.icon-placeholder:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1000%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 190px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding-top: 4px;
  padding-left: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.load-change {
  z-index: 1000;
  position: fixed;
  height: 56px;
  width: 50%;
  left: 50%;
  right: 0%;
  top: calc(100% - 56px);
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(39, 20, 2, 0.12);
  border-radius: 28px;
}

.image-load {
  margin: 12px;
}

.load-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  margin-top: 16px;
}

.load-text-right {
  cursor: pointer;
  margin-top: -18px;
  margin-right: 24px;
}

.text {
  margin-left: 7px;
  color: #E14761;
}

.photo-preview {
  height: 56px;
  background: rgba(61, 117, 228, .08);
  border-radius: 8px;
  margin-top: 24px;
  overflow: hidden;
  position: relative;

  &__button {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }

  &__image {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-top: 32px;
    padding-right: 40px;
    padding-bottom: 40px;
    display: flex;
  }
}

.hidden__block {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-100 {
  width: 100%;
}

.table {
  display: table
}

.mb-120 {
  margin-bottom: 120px;
}

.image {
  width: 56px;
  margin-top: -8px;
  border-radius: 30px;
}

.w-650 {
  max-width: 650px;
}

.justify {
  text-align: justify;
}

.photo {
  height: 56px;
  border-radius: 8px;
}

.comment__result {
  margin-top: 36px;
  display: inline-grid;
  width: 100%;
}

.comment__items {
  margin-top: 36px;
  display: inline-grid;
  width: 100%;
}

.padding {
  width: 56px;
  margin-top: -8px;
  border-radius: 30px;
}

.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

.grid-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
<style>
.fargo {
  background-color: unset !important;
}

.matrix {
  background-color: unset !important;
}
</style>
